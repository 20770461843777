.navcatContainer {
  overflow: auto;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 56%);
  z-index: 1000;
  bottom: 0;
  padding-right: 16vw;
}
.navcatContainerInner {
  height: 100%;
  background: white;
}
.hideMenu {
  display: none;
}
