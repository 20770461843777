body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  background-color: #2c78b5;
  background-image: url('../assets/img/desktop-bg@2x.png'), linear-gradient(to bottom, #afe6ff 1%, #2c78b5);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

* {
  box-sizing: border-box;
}

pre {
  white-space: normal;
}

/* List styling -- use 'disc' mixin to restore default styling */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Remove shadow and outline on focus */
input[type='name'],
input[type='email'],
input[type='text'],
input[type='tel'],
input[type='submit'],
input[type='number'],
input[type='password'],
input[type='url'],
iput[type='radio'],
textarea,
button,
a,
select {
  &:focus {
    outline: none !important;
    box-shadow: 0, 0, 0, transparent;
    text-decoration: none;
  }
}

input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  outline: none;
  box-shadow: 0, 0, 0, transparent;
}

/* Add button cursor styles */
button,
input[type='submit'],
input[type='button'] {
  cursor: pointer;
}

button:disabled,
input[type='submit']:disabled,
input[type='button']:disabled {
  cursor: not-allowed;
}

/* images can't be wider than their containers */
img {
  max-width: 100%;
}

/* tables inherit font size (some browsers set a default table font size) */
table {
  font-size: inherit;
}

/* set cursor to pointer for specific elements -- TODO: update for Angular2 */
a {
  cursor: pointer;
  outline: none;
}

/* STARTSTUDIO CONTAINER */
.ssContainer {
  width: 100%;
  max-width: rem-calc(768);
  margin: 0 auto;
  // margin-top: 8px;
  // padding: 0 rem-calc(16);
}

/* STARTSTUDIO SPINNER */
.ssSpinner:before {
  content: '';
  display: inline-block;
  width: 36px;
  height: 36px;
  border: 6px solid $gray-lt;
  border-radius: 50%;
  border-top-color: $gray;
  border-bottom-color: $gray;
  animation: ssSpin 2s linear infinite;
}

@keyframes ssSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
