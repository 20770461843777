.customizeProduct {
  position: relative;

  &__header {
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    width: 100%;
  }

  &__content {
    padding: 1rem 1.5rem;
  }

  &__flex {
    display: flex;
    flex-direction: row;
  }

  &__image {
    flex: 1;
    height: 300px;
    text-align: center;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  &__form {
    // width: 304px;
    margin-top: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  &__thumbnails {
    display: flex;
    margin-top: 0.5rem;
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  &__thumbnail {
    margin: 1px;
    min-width: 96px;
    width: 96px;
    border: solid 2px transparent;
    cursor: pointer;
  }

  &__thumbnail--active {
    border: solid 2px #e08d8f;
  }
}

@include breakpoint(768px down) {
  .customizeProduct {
    &__content {
      padding: 1.5rem 0.5rem;
    }

    &__flex {
      align-items: center;
      flex-direction: column;
    }

    &__image {
      max-width: 288px;
      margin-bottom: 2rem;
    }

    &__form {
      // width: 288px;
      margin-left: 0;
    }

    &__thumbnails {
      width: 100%;
    }
  }
}
