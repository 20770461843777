.accordion {
  &__item {
    border-bottom: 1px solid $border;
    &__header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 8px;
      &__text {
        @include bold;
        color: $blue;
        font-size: 16px;
        line-height: 1.25;
      }
      &:hover {
        background-color: rgba($teal, 0.25);
      }
    }
    &__body {
      font-size: 14px;
      line-height: 1.25;
      // display: none;
      padding: 0 8px;
      opacity: 0;
      overflow: hidden;
      height: 0;
      transition: all 0.4s linear;
      &--open {
        // display: block;
        opacity: 1;
        height: auto;
        padding: 8px 8px 24px 8px;
      }
    }
  }
}
