.cart {
  &__header {
    padding: 0.5rem 1.5rem 0 1.5rem;
    position: relative;
  }

  &__title {
    font-size: 24px;
    line-height: 1.33;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 16px;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  &__content {
    padding: 1.5rem;
  }

  &__items {
    border-top: 1px solid $border;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__right {
    text-align: right;
  }

  &__checkout {
    text-align: center;
    margin: 1.5rem 0;

    button {
      width: 288px;
    }
  }

  &__empty {
    color: $gray;
    font-size: 18px;
    text-align: center;
  }
}

.cartItem {
  border-bottom: 1px solid $border;
  padding: 1.5rem 0;

  &__flex {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  &__images {
    flex: 2;
    margin-right: 1rem;
  }

  &__image {
    display: inline-block;
    width: fit-content;
    text-align: center;

    &Label {
      line-height: 1;
      letter-spacing: normal;
      font-size: 12px;
      margin: 8px 0;
    }

    img {
      margin: 1px;
      max-width: 192px;
    }
  }

  &__actions {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__quantity {
    margin-right: 4px;
    position: relative;
    width: 128px;

    input {
      padding-right: 64px;
      width: 128px;
    }

    button {
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
    }

    strong {
      display: block;
      line-height: 1.25;
    }
  }
}

@include breakpoint(768px down) {
  .cart {
    &__header {
      padding: 0 0.5rem;
    }

    &__title {
      top: 8px;
    }

    .coloredDivider {
      margin-top: 0;
    }

    &__content {
      padding: 0 0.5rem 1.5rem 0.5rem;
    }
  }

  .cartItem {
    &__images {
    }

    &__image {
      img {
        max-width: 96px;
      }
    }
  }
}
