.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 48px;
  height: 48px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  letter-spacing: normal;

  @include normal;
  color: $gray-dk;
  font-size: 16px;
  line-height: 1.25;
  text-transform: none;
  transition: all 0.3s ease;
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 24px;
    width: 24px;
    background-color: white;
    border: 1px solid $gray;
    border-radius: 50%;
    transition: all 0.3s ease;
    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
      transition: all 0.3s ease;
    }
  }
  /* When the radio button is checked, add a lime backgroun */
  input:checked ~ .checkmark {
    background-color: $lime;
    border-color: $lime;
  }
  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 7.25px;
    left: 7.25px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    transition: all 0.3s ease;
  }
}
