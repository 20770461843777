.popover {
  animation: scaleIn 0.4s;
  background-color: white;
  color: #343a40;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  font-size: 1em;
  font-family: 'Roboto; sans-serif';
  padding: 1rem;
  width: auto;
  z-index: 10;
}
