.login {
  &__hero {
    position: relative;

    &__shape {
      position: absolute;
      z-index: 1;
    }

    &__image {
      margin-top: 6rem;
    }
  }

  &__form {
    background-color: white;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 1rem;

    .italics {
      @include light;
      @include font-range(12, 18);
      font-style: italic;
      line-height: 1.5;
      margin: 2rem 0;
      padding: 0 4rem;
      // width: 420px;
      text-align: center;
    }

    .accessCodeInputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .input {
        border-radius: 0px;
        font-size: 24px;
        height: 48px;
        width: 48px;
        padding: 0;
        text-align: center;

        &:first-of-type {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      .submit {
        border-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .textButton {
      margin-top: 1rem;
    }
  }

  &__imageGallery {
    .image-gallery,
    .image-gallery-content,
    .image-gallery-slide-wrapper,
    .image-gallery-swipe,
    .image-gallery-slides,
    .image-gallery-slide,
    .image-gallery-image {
      min-height: calc(100vh - 68px);
      object-fit: cover;
    }

    .image-gallery-thumbnail {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      border: solid 1px #e08d8f;
      &.active {
        border: solid 3px #e08d8f;
      }
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-inner {
      height: 100%;
    }

    .image-gallery-thumbnail .image-gallery-thumbnail-inner .image-gallery-thumbnail-image {
      height: 100%;
      object-fit: cover;
    }

    .image-gallery-thumbnail + .image-gallery-thumbnail {
      margin-left: 8px;
    }

    .image-gallery-thumbnails {
      padding: 8px 0;

      &-wrapper {
        margin-top: -90px;
      }
    }

    .image-gallery-image {
      padding-top: 100px;
      object-fit: cover;
    }
  }
}

@include breakpoint(768px down) {
  .login {
    &__hero {
      &__image {
        margin-top: 3rem;
      }
    }

    &__form {
      padding: 0.5rem 1rem 0rem 1rem;

      .italics {
        margin: 1rem 0 2rem 0;
        padding: 0;
      }
    }
  }
}
