.nav {
  background-color: $blue;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
  }

  &__left {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__right {
    flex: 2;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .navLink {
    @include bold;
    line-height: 1;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    &:not(:first-of-type) {
      margin-left: 32px;
    }

    .cart-count {
      height: 16px;
      border-radius: 8px;
      background-color: #e08d8f;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      line-height: 1;
      position: absolute;
      top: 4px;
      left: 28px;
      padding: 3px 5px;
    }
  }
}

@include breakpoint(768px down) {
  .nav {
    &__content {
      padding: 0;
    }
    &__logo {
      margin-left: 8px;
    }
    &__right {
      flex: 1;
    }
    .navLink {
      &:not(:first-of-type) {
        margin-left: 0;
      }
    }
  }
}
