$breakpoints: (
  small: rem-calc(0),
  medium: rem-calc(640),
  large: rem-calc(1200),
  xlarge: rem-calc(1440),
  xxlarge: rem-calc(1920),
) !default;

$breakpoint-classes: (small medium large) !default;

@mixin breakpoint($val: small) {
  $bp: nth($val, 1);
  $bpMax: 0;
  $dir: if(length($val) > 1, nth($val, 2), up);
  $str: 'only screen';
  $named: false;

  @if $bp == 'landscape' or $bp == 'portrait' {
    $str: $str + ' and (orientation: #{$bp})';
  } @else {
    @if type-of($bp) == 'string' {
      @if map-has-key($breakpoints, $bp) {
        @if $dir == 'only' {
          $next-bp: map-next($breakpoints, $bp);
          @if $next-bp == null {
            $bpMax: null;
          } @else {
            $bpMax: $next-bp - (1/16);
          }
        }
        $bp: map-get($breakpoints, $bp);
        $named: true;
      } @else {
        $bp: 0;
      }
    }

    @if unit($bp) == 'px' or unit($bp) == '' {
      $bp: rem-calc($bp);
    }
    $bp: strip-unit($bp) * 1em;

    @if $bp > 0 or $dir == 'only' {
      // And lo, a media query was born
      @if $dir == 'only' {
        @if $named == true {
          $str: $str + ' and (min-width: #{$bp})';
          @if $bpMax != null {
            $str: $str + ' and (max-width: #{$bpMax})';
          }
        } @else {
          @debug 'ERROR: Only named media queries can have an "only" range.';
        }
      } @else if $dir == 'down' {
        $max: $bp - (1/16);
        $str: $str + ' and (max-width: #{$max})';
      } @else {
        $str: $str + ' and (min-width: #{$bp})';
      }
    }
  }

  @if $bp == 0em and $dir != 'only' {
    @content;
  } @else {
    @media #{$str} {
      @content;
    }
  }
}

@mixin each-breakpoint($class, $omit: ()) {
  @each $size in $breakpoint-classes {
    @if index($omit, $size) == null {
      $val: map-get($breakpoints, $size);
      .#{$size + '-' + $class} {
        @include breakpoint($size) {
          @content;
        }
      }
    }
  }
}
