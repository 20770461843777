.orderConfirmation {
  position: relative;
  &__header {
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    width: 100%;
  }
  &__content {
    padding: 1.5rem;
    &--tealBg {
      background-color: rgba($teal, 0.25);
    }
  }
  &__flexRow {
    display: flex;
    flex-direction: row;
    &--margin {
      margin-bottom: 3rem;
    }
    &--spaceBetween {
      justify-content: space-between;
    }
    &__item {
      flex: 1;
    }
  }
  &__right {
    text-align: right;
  }
  .socialButtons {
    text-align: center;
    margin: 3rem 0 1rem 0;
    .iconCircleButton {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}

@include breakpoint(768px down) {
  .orderConfirmation {
    &__content {
      padding: 1.5rem 0.5rem;
    }
    &__flex {
      align-items: center;
      flex-direction: column;
    }
  }
}
