.recentlyViewed {
  margin-top: 2rem;
  padding: 1.5rem 0;
  text-align: center;

  .productsContainer {
    margin: 1rem auto 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    width: 465px;
  }
  .starIcon {
    color: $yellow;
    text-shadow: 0 1px 0 $gray-dker;
    font-size: 16px;
  }

  &Product {
    cursor: pointer;
    position: relative;
    margin: 12px 2px;
    width: fit-content;
    &__image {
      border: solid 1px $gray-lter;
      height: 96px;
      width: 140px;
    }
    &__name {
      font-size: 12px;
      line-height: 1;
      color: $gray-dk;
      margin-top: 8px;
    }
    &__price {
      @include bold;
      font-size: 12px;
      line-height: 1;
      color: $gray-dk;
      margin-top: 4px;
    }
    &__star {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
}

@include breakpoint(768px down) {
  .recentlyViewed {
    .productsContainer {
      width: auto;
    }
  }
}
