/* APP-SPECIFIC GLOBAL VARIABLES
---------------------------------------- */
$fa-font-path: '../webfonts';

// colors
$gray-dker: #1f262c;
$gray-dk: #596066;
$gray: #9ba3aa;
$gray-lt: #bac1c8;
$gray-lter: #dce0e3;
$gray-ltest: #f8fafd;
$border: $gray-lter;

$blue: #1c659f;
$teal: #3cd0de;
$lime: #92c954;
$green: #3c9d3a;
$pink: #e08d8f;
$red: #d85b5e;
$yellow: #ffca00;
