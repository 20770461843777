.contact {
  min-height: calc(100vh - 64px);

  &Flex {
    display: flex;
    flex-direction: row;
  }
  &Form {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    input,
    textarea,
    button {
      margin-top: 1.5rem;
      width: 100%;
    }
  }
  &Info {
    //padding: 1.5rem;
    //width: 304px;
    text-align: center;

    &__contactInfoWrapper {
      padding: 1.5rem;
      background-color: #fafafa;

      &__availability {
        font-size: 14px;
        text-align: left;
        margin-top: 0.5rem;
      }
    }
  }
}

@include breakpoint(768px down) {
  .contact {
    padding: 0;

    &Flex {
      flex-direction: column;
      align-items: center;
    }
    &Form {
      margin-right: 0;
      width: 304px;
    }
    &Info {
      background-color: #fafafa;
      margin-top: 6%;
    }
  }
}
