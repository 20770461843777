.searchBox {
  .searchLabel {
    display: block;
  }
  .searchInput {
    width: 200px;
  }
  .searchListBox {
    width: 96%;
    max-width: 465px;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
    list-style: none;
    background-color: #ffffff;
    max-height: 200px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;

    & li {
      background-color: #ffffff;
      color: #596066;
      font-size: 16px;
      padding: 13px 8px;
    }
    & li:hover {
      background-color: rgba(60, 208, 222, 0.25);
      cursor: pointer;
    }
  }
}
