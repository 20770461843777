// General typographic rules
html,
body {
  @include sans;
  @include normal;
  color: $gray-dk;
  font-size: 100%; // 16px in most browsers. If you change this value, you'll want to change the $rem-base variable in _functions.scss
  line-height: 1.25;
}

h1 {
  @include medium;
  color: $blue;
  font-size: 24px;
  line-height: 1.33;
  text-transform: uppercase;
}
h2 {
  @include medium;
  color: $blue;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
}
h3 {
  @include medium;
  color: $blue;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
}
h4 {
  @include bold;
  color: $gray-dk;
  font-size: 16px;
  line-height: 1.25;
}
h5 {
  @include normal;
  color: $gray-dk;
  font-size: 14px;
  line-height: 1.29;
}
h6 {
  @include normal;
  color: $gray-dk;
  font-size: 12px;
  line-height: 1;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
// Add margin back to p or h tags
.textMargin {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

label {
  @include medium;
  color: #3c4349;
  font-size: 12px;
  letter-spacing: 1.5px;
  line-height: 1;
  text-transform: uppercase;
}

.errorText {
  color: $red;
  line-height: 1.29;
}

.mutedText {
  font-size: 14px;
  line-height: 1.29;
  color: $gray;
}

.smallMutedText {
  font-size: 12px;
  line-height: 1;
  color: $gray;
  letter-spacing: normal;
}

// Icon fonts
$font-path: '../assets/fonts';

.transform-none {
  text-transform: none !important;
}
