.productCarousel {
  margin: 0 auto;
  width: 571px;
}

@include breakpoint(770px down) {
  .productCarousel {
    width: 371px;
  }
}

@include breakpoint(420px down) {
  .productCarousel {
    width: 100vw;
  }
}
