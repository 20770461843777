@import './scss/fontawesome/scss/fontawesome.scss';
@import './scss/fontawesome/scss/solid.scss';
@import './scss/fontawesome/scss/brands.scss';

@import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

@import './scss/functions';
@import './scss/breakpoints';
@import './scss/variables';
@import './scss/mixins';
@import './scss/defaults';
@import './scss/typography';

// Components
@import './scss/components/accordion';
@import './scss/components/nestedAccordion';
@import './scss/components/extraFooter';
@import './scss/components/footer';
@import './scss/components/nav';
@import './scss/components/popovers';
@import './scss/components/productCarousel';
@import './scss/components/recentlyViewed';
@import './scss/components/storeFilters';

// Modals
@import './scss/components/modals/accessCode';
@import './scss/components/modals/orderConfirmation';
@import './scss/components/modals/productCustomize';
@import './scss/components/modals/storeWelcome';

// Routes
@import './scss/routes/cart';
@import './scss/routes/checkout';
@import './scss/routes/contact';
@import './scss/routes/faq';
@import './scss/routes/login';
@import './scss/routes/product';
@import './scss/routes/store';
@import './scss/routes/fundraiser';

// Widgets
@import './scss/widgets/checkbox';
@import './scss/widgets/customfilter.scss';
@import './scss/widgets/radio';

// GLOBAL STYLING
$nav-height: 64px;
$footer-height: 152px;

.routesContainer {
  padding-top: 64px;
  min-height: calc(100vh - 152px);
}

.pageContainer {
  background-color: white;
  width: 100%;
  &.boxed {
    max-width: rem-calc(768);
    margin: 0 auto;
  }
}
.boxedContainer {
  max-width: rem-calc(768);
  margin: 0 auto;
}

@include breakpoint(768 down) {
  .routesContainer {
    padding-top: 48px;
    min-height: calc(100vh - 152px);
  }
}

.relativeContainer {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.divider {
  border: none;
  border-bottom: 1px solid $border;
  margin: 1rem 0;
}

.coloredDivider {
  border: none;
  height: 2px;
  width: 100%;
  background-image: linear-gradient(to left, #96e7ee, #b3f16c 0%);
  margin: 1rem 0;
}

.pinkDivider {
  border: none;
  height: 4px;
  width: 100%;
  background: $pink;
}

.stripeImg {
  margin-top: 1.5rem;
}

@mixin button($bg, $bg-hover, $text) {
  @include sans;
  background-color: $bg;
  border-radius: 4px;
  border: none;
  color: $text;
  font-size: 16px;
  line-height: 1;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: $bg-hover;
    border: none;
  }
}

@mixin hollow-button($color, $color-hover) {
  @include sans;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $color;
  color: $color;
  font-size: 16px;
  line-height: 1;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    border: 1px solid $color-hover;
    color: $color-hover;
  }
}

@mixin text-button($color, $color-hover) {
  @include sans;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: $color;
  font-size: 16px;
  line-height: 1;
  padding: 1rem 0;
  min-width: 48px;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent;
    border: none;
    color: $color-hover;
  }
}

@mixin icon-button($color, $color-hover, $text, $text-hover) {
  @include sans;
  background-color: $color;
  border: none;
  color: $text;
  font-size: 16px;
  text-align: center;
  padding: 0;
  transition: all 0.3s ease;

  i {
    font-size: 24px;
    height: 48px;
    line-height: 48px;
    width: 48px;
  }

  &:hover {
    background-color: $color-hover;
    border: none;
    color: $text-hover;
  }
}

@mixin icon-circle-button($color, $color-hover, $text) {
  @include sans;
  background-color: $color;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: $text;
  font-size: 24px;
  text-align: center;
  height: 48px;
  width: 48px;
  padding: 0;
  transition: all 0.3s ease;

  i {
    height: 24px;
    line-height: 24px;
    width: 48px;
  }

  &:hover {
    background-color: $color-hover;
    border: none;
  }
}

.button {
  @include button($lime, $green, white);
}

.hollowButton {
  @include hollow-button($lime, $green);
}

.textButton {
  @include text-button($lime, $green);

  &--gray {
    @include text-button($gray, $gray);
  }
}

.iconButton {
  @include icon-button(transparent, transparent, $lime, $green);

  &--white {
    @include icon-button(transparent, transparent, white, rgba(white, 0.6));
  }

  &--gray {
    @include icon-button(transparent, transparent, $gray, $gray);
  }

  &--greenBg {
    @include icon-button($lime, $green, white, white);
  }
}

.iconCircleButton {
  @include icon-circle-button($lime, $green, white);
}

.input {
  @include sans;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $gray-lt;
  color: $gray-dk;
  font-size: 16px;
  padding: 0.875rem 0.7rem;
  transition: all 0.3s ease;

  @include placeholder {
    color: $gray;
  }

  &.input-with-label:not(select) {
    padding: 1.4rem 0.875rem 0.7rem;
  }
  &.input-with-label:not(input) {
    padding-top: 0.98rem;
    padding-bottom: 0.98rem;
  }

  &:focus {
    border: 1px solid $gray-dk;
  }
}
.input-label {
  span {
    position: relative;
    bottom: -1.2rem;
    padding-left: 0.875rem;
  }
}

@mixin link($color, $color-hover) {
  color: $color;
  padding: 1rem 0;
  line-height: 1;
  text-decoration: underline;
  transition: color 0.4s ease;

  i {
    width: 48px;
  }

  &:active,
  &:hover {
    color: $color-hover;
  }
}

.displayBlock {
  display: block;
}

.link {
  @include link($lime, $green);

  &--white {
    @include link(white, rgba(white, 0.6));
  }
}

@include breakpoint(768 up) {
  .hideOnDesktop {
    display: none;
  }
}

@include breakpoint(768 down) {
  .hideOnMobile {
    display: none;
  }
}
