.accessCode {
  padding: 1.5rem;

  .coloredDivider {
    margin: 1rem 0 1.5rem 0;
  }

  .required {
    @include normal;
    color: $gray;
    font-size: 14px;
    line-height: 1.29;
  }

  .inputs {
    display: flex;
    flex-direction: column;

    // input, select {
    //   margin-top: 24px;
    //   width: 100%;
    // }
  }

  .buttons {
    margin-top: 24px;
    text-align: right;

    &--spaceBetween {
      margin-top: 24px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
