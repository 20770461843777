.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 48px;
  height: 48px;
  margin: 0;
  cursor: pointer;
  user-select: none;

  @include normal;
  color: $gray-dk;
  font-size: 16px;
  line-height: 1.25;
  text-transform: none;
  transition: all 0.3s ease;
  /* Hide the browser's default checkbox button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom checkmark button */
  .checkmark {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 24px;
    width: 24px;
    background-color: white;
    border: 1px solid $gray;
    border-radius: 2px;
    transition: all 0.3s ease;
    /* Create the indicator (the check - hidden when not checked) */
    &:after {
      content: '\f00c';
      color: $lime;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 16px;
      position: absolute;
      display: none;
      text-align: center;
      transition: all 0.3s ease;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
    }
  }
  /* When the checkbox is checked, add a lime background */
  input:checked ~ .checkmark {
    border-color: $lime;
  }
  /* Show the indicator (the check) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
}
