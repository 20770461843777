.storeWelcome {
  display: flex; //
  justify-content: center; //
  text-align: center;
  // padding-bottom: 3rem;

  .welcome {
    position: absolute;
    z-index: 1;
    top: 0;

    .topBanner {
      width: 1024px;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 3.5rem 5.5625rem 4rem 5.625rem;
      box-shadow: 0 16px 30px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;

      h1 {
        @include medium;
        color: $blue;
        font-size: 64px;
        text-align: center;
        text-transform: none;

        .offset {
          @include light;
          color: $gray;
        }
      }

      .paragraph {
        margin-top: 2.2rem;
        font-size: 1.5rem;
        color: #596066;
        font-style: italic;
        line-height: 1.35;
      }
    }

    .accessCodeWrapper {
      padding: 4rem 21.125rem 0rem 22.125rem;
    }
  }

  .italics {
    @include light;
    @include font-range(12, 16);
    font-style: italic;
    line-height: 1.67;
    margin: 2rem 0;
    // width: 420px;
    text-align: center;
  }

  .getStarted {
    padding: 1rem 4rem;
  }
}

// override Image gallery slider
.image-gallery-image {
  padding: 0 !important;
}

.image-gallery-image img {
  min-height: 810px;
}

@include breakpoint(768px down) {
  .login {
    .storeWelcome {
      display: flex;
      justify-content: center;

      .welcome {
        width: 85%;
        padding: 0rem;

        .topBanner {
          width: 100%;
          padding: 1rem 0 0.8rem 0;
          margin-bottom: 2rem;
          // border-radius: 0px;

          h1 {
            font-size: 20px;
          }

          .paragraph {
            margin-top: 0.4rem;
            font-size: 9.5px;
          }
        }

        .accessCodeWrapper {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}
