.checkout {
  &__header {
    padding: 0.5rem 1.5rem 0 1.5rem;
    position: relative;
  }

  &__title {
    font-size: 24px;
    line-height: 1.33;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 16px;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  &__content {
    padding: 1.5rem;
  }

  &__form {
    margin: 2rem auto;
    width: 512px;

    &__section {
      margin-top: 2rem;
    }

    &__inputRow {
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;
    }

    .input,
    .input-label {
      flex: 1;
      width: 100%;

      .label {
        display: block;
      }
    }
  }

  &__right {
    margin: 2rem auto;
    max-width: 512px;
    font-weight: 500;
    color: #7c8389;
  }

  &__purchase {
    text-align: center;
    margin: 1.5rem 0;

    button {
      width: 288px;
    }
  }
}

@include breakpoint(768px down) {
  .checkout {
    &__header {
      padding: 0 0.5rem;
    }

    &__title {
      top: 8px;
    }

    .coloredDivider {
      margin-top: 0;
    }

    &__content {
      padding: 0 0.5rem 1.5rem 0.5rem;
    }

    &__form {
      margin: 0;
      width: 100%;
    }
  }
}
