.page-with-sidebar {
  display: flex;
  .productNavigator {
    @include breakpoint(768px down) {
      display: none;
    }
    flex: 0 0;
    min-width: 256px;
    background-color: #f8fafc;
    max-height: calc(100vh - 64px);
    overflow: auto;

    &__header {
      padding: 1.1rem 0;
      color: $blue;
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      border-bottom: solid 1px #dce0e3;
    }
  }
  .page-not-sidebar {
    flex: 1 1;
    display: flex;
    min-height: calc(100vh - 64px);
  }
}
.productNavigatorMobile {
  .productNavigator {
    display: block !important;
    &__header {
      display: none;
    }
  }
}
.store {
  display: flex;

  &__content {
    flex: 23.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-left:64px;
    // padding-right:64px;
    // padding-top:24px;
    padding: 24px 42px 0px 47px;
    //width: 100%;

    min-height: calc(100vh - 216px);
  }

  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    @include breakpoint(768px up) {
      position: relative;
      border-radius: 4px;
      overflow: hidden;
    }

    input {
      width: 25.875rem;
      border-radius: 4px 0px 0px 4px;
    }

    button {
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      @include breakpoint(768px up) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    button.clear {
      right: 3.1em;
      color: #92c954;
      background: transparent;
      border: none;
      font-size: 1.2em;

      &:hover {
        color: #3c9d3a;
      }
    }
  }

  .alignCenter {
    text-align: center;
  }

  .alignRight {
    text-align: right;
  }

  .starIcon {
    color: $yellow;
    text-shadow: 0 1px 0 $gray-dker;
    font-size: 16px;
  }

  .productsList {
    display: grid;
    grid-gap: 0;
    width: 100%;
    margin: 0 auto;
    justify-content: space-around;
    grid-template-columns: repeat(1, 200px);

    @include breakpoint(760px up) {
      grid-template-columns: repeat(3, 15vw);
    }

    @include breakpoint(760px up) {
      grid-template-columns: repeat(3, 15vw);
    }

    @include breakpoint(1120px up) {
      grid-template-columns: repeat(5, 12vw);
    }

    @include breakpoint(1800px up) {
      grid-template-columns: repeat(5, 14vw);
    }
  }

  .categoriesContainer {
    display: none;
  }
  .categoriesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 465px;
  }

  .storeProduct {
    cursor: pointer;
    position: relative;
    //margin: 12px 2px;
    padding: 14.5px 0;
    width: 100%;

    &__image {
      border: solid 1px $gray-lter;
      height: 200px;
      width: 200px;
      text-align: center;
      position: relative;

      @include breakpoint(768px up) {
        height: 15vw;
        width: 15vw;
      }

      @include breakpoint(1120px up) {
        height: 12vw;
        width: 12vw;
      }

      @include breakpoint(1800px up) {
        height: 11vw;
        width: 11vw;
      }

      img {
        height: 100%;
        object-fit: contain;
        padding: 0.3em;
      }
    }

    &__name {
      font-size: 12px;
      line-height: 1;
      color: $gray-dk;
      margin-top: 8px;
      width: auto;
      //width: 228px;
    }

    &__price {
      @include bold;
      font-size: 12px;
      line-height: 1;
      color: $gray-dk;
      margin-top: 4px;
    }

    &__star {
      position: absolute;
      top: 3px;
      right: 5px;
    }
  }

  .storeCategory {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin: 2px;
    height: 96px;
    width: 150px;

    &__image {
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      display: flex;
      border: 1px solid $gray;
      background: #93c954;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.2em;
      text-align: center;
      padding: 0.5rem;
    }

    &__name {
      text-align: center;
    }
  }

  .productNavigator {
    flex: 4;
    min-width: 256px;
    background-color: #f8fafc;
    max-height: calc(100vh - 64px);
    position: sticky;
    top: 64px;
    overflow: auto;

    &__header {
      padding: 1.1rem 0;
      color: $blue;
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      border-bottom: solid 1px #dce0e3;
    }
  }
}

.store__mobileFilters {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  z-index: 11;

  .storeFilters {
    height: 100%;
    width: 256px;
    overflow: auto;
  }

  &__scrim {
    position: fixed;
    top: 48px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($gray-dker, 0.5);
    z-index: 10;
  }
}

@include breakpoint(768px down) {
  .categoriesContainer {
    display: block !important;
  }
  .store {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 6px;

    &__content {
      align-items: normal;
      padding: 0;
      min-height: calc(100vh - 216px);
    }

    .searchContainer {
      display: flex;
      flex-direction: row;
      input {
        width: 100%;
      }
    }

    .categoriesList {
      justify-content: center;
      width: auto;
    }

    .productNavigator {
      display: none;
    }
  }
}
