.extraFooter {
  background-image: url('../../assets/img/mountains.png'),
    linear-gradient(to bottom, rgba(60, 168, 225, 0.7), rgba(60, 208, 222, 0.4));
  background-position: bottom;
  background-repeat: no-repeat;
  border-top: 4px solid $pink;
  // padding: 2rem 1.5rem;
  padding: 2rem 1.5rem 14.5rem 1.5rem;

  p {
    color: #3c4349;
    font-size: 14px;
    line-height: 1.29;
    margin-top: 16px;
  }
}
