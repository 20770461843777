.storeFilters {
  background-color: #f8fafc;
  border-left: 1px solid $border;
  height: 100%;
  width: 256px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border;
    padding: 0.5rem;
    width: 100%;
  }
  &__content {
    padding: 1rem 0.5rem;
  }
}
