.product {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    padding: 0 1.5rem;
    width: 100%;
    position: relative;

    &__flex {
      display: flex;
      flex-direction: row;

      &___backBtn {
        left: 0;

        button > span {
          width: 36px;
          height: 20px;
          font-family: Ubuntu;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #9ba3aa;
        }
      }
    }

    &__details {
      padding: 8px 1.5rem;
    }

    &__back {
      position: absolute;
      left: 0;
      height: 68px;
      button {
        height: 68px;
      }
    }
  }

  &__name {
    @include font-range(20, 24);
    margin-bottom: 8px;
  }

  &__desc {
    @include font-range(12, 14);
  }

  &__content {
    // width : 817px;
    padding: 0 1.5rem 0 1.5rem;
  }

  &__flex {
    display: flex;
    flex-direction: row;
  }

  &__customize {
    margin-bottom: 1rem;
  }

  &__images {
    //margin-right: 1.5rem;
    // min-width: 392px;
  }

  &__info {
    &__flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__description {
      margin-left: 25px;
      color: #596066;
    }

    &__price {
      background-color: #f8fafc;
    }
  }

  &__image {
    min-width: 100%;
    width: 100%;
  }

  &__thumbnails {
    display: flex;
    margin-top: 0.5rem;
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  &__thumbnail {
    margin: 1px;
    min-width: 96px;
    width: 96px;
  }

  &__price {
    margin-right: 1.5rem;
  }

  &__quantity {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    label {
      margin-right: 8px;
    }

    &__input {
      position: relative;
      width: 100%;

      input {
        padding-right: 64px;
        width: 128px;
      }

      button {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@include breakpoint(768px down) {
  .product {
    &__header {
      padding: 0.5rem 0.5rem 0 0.5rem;

      &__details {
        padding: 8px;
      }

      &__back {
        position: static;
        left: 0;
        height: 68px;
        button {
          height: 68px;
          span {
            display: none;
          }
        }
      }
    }

    &__content {
      width: 100vw;
      // padding: 0;
      // padding: 0 .5rem 0 .5rem;
    }

    &__flex {
      flex-direction: column;
    }

    &__name {
      line-height: 1;
    }

    &__images {
      margin-right: 0;
      margin-bottom: 3rem;
      // min-width: 304px;
    }

    &__thumbnails {
      margin-top: 2px;
    }

    &__info {
      &__price {
        margin-left: 0;
      }

      &__description {
        margin-left: 0;
      }
    }
  }
}
