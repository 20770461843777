.nestedAccordion {
  &__item {
    border-top: 1px solid $border;

    &__noBorder {
      border: none;
    }

    &__header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 8px;
      &__text {
        @include bold;
        color: $blue;
        font-size: 16px;
        line-height: 1.25;

        &__lvl1 {
          @include bold;
          color: $blue;
          font-size: 16px;
          line-height: 1.25;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        &__lvl2 {
          @include bold;
          color: $blue;
          font-size: 15px;
          line-height: 1.25;
          padding-left: 8px;
          letter-spacing: 0.5px;
        }
        &__lvl3 {
          font-weight: normal;
          color: #1c659f;
          padding-left: 15px;
        }
        &__lvl4 {
          color: #92c954;
          border: none;
          padding-left: 24px;
        }
        &__leaf {
          color: #92c954;
          font-weight: 400;
        }
      }
      &:hover,
      &__selected {
        background-color: rgba($teal, 0.25);
      }
    }
    &__body {
      font-size: 14px;
      line-height: 1.25;
      // display: none;
      // padding-left: 8px;
      opacity: 0;
      overflow: hidden;
      height: 0;
      transition: all 0.4s linear;
      &--open {
        // display: block;
        opacity: 1;
        height: auto;
        //padding: 8px 8px 24px 8px;
      }
    }
  }
}
