.outer-footer {
  border-top: 4px solid $pink;
}
.footer {
  // background-image: linear-gradient(to bottom, #3495c4, #1c659f);
  text-align: center;
  padding: 1.5rem;
  width: 100%;

  h6 {
    color: white;
  }
  .buttons {
    margin: 0.5rem 0 2rem 0;
    .iconCircleButton {
      display: inline-block;
      i {
        line-height: 48px;
      }
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}
