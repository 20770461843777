.customFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 48px;
  height: 48px;
  margin: 0;
  cursor: pointer;
  user-select: none;

  @include normal;
  color: $gray-dk;
  font-size: 16px;
  line-height: 1.25;
  text-transform: none;
  transition: all 0.3s ease;
  /* Hide the browser's default checkbox button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom checkmark button */
  .customFilterCheckmark {
    background-image: url(../../assets/img/switch-off.png);
    position: absolute;
    // top: 12px;
    left: -22px;
    // height: 24px;
    // width: 24px;
    height: 44px;
    width: 57px;
    background-color: white;
    // border: 1px solid $gray;
    // border-radius: 2px;
    transition: all 0.3s ease;
    /* Create the indicator (the check - hidden when not checked) */
    &:after {
      background-image: url(../../assets/img/switch-on.png);
      content: '';
      color: $lime;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      // font-size: 16px;
      font-size: 0px;
      position: absolute;
      display: none;
      text-align: center;
      transition: all 0.3s ease;
      height: 44px;
      width: 57px;
      // top: 3px;
      left: 5px;
      // width: 16px;
      // height: 16px;
    }
  }
  /* When the checkbox is checked, add a lime background */
  input:checked ~ .customFilterCheckmark {
    border-color: $lime;
  }
  /* Show the indicator (the check) when checked */
  input:checked ~ .customFilterCheckmark:after {
    display: block;
  }
}
