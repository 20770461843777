.fundraiser {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top-banner {
    width: 768px;
    height: 267px;
    background-color: #92c954;
    display: flex;
    flex-direction: column;

    &__first-line {
      padding-top: 91px;
      display: flex;
      justify-content: center;
      color: #ffffff;

      &__star {
        width: 23px;
        height: 18px;
        background-color: #ffffff;
        font-family: FontAwesome5Free-Solid;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }

      &__text-the-ultimate {
        width: 276px;
        height: 20px;
        font-family: Ubuntu;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: 3.87px;
        text-align: center;
        margin-left: 10px;
        margin-right: 9px;
        color: #ffffff;
      }
    }

    &__second-line {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 85px;

      &__text-fundraiser {
        height: 20px;
        width: 513px;
        font-family: Ubuntu;
        font-size: 84px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.24;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  &__customized-products-banner {
    width: 768px;
    height: 362px;
    padding: 32px 43px 33px 41px;
    background-color: #1c659f;
    color: #ffffff;
    font-family: Ubuntu;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      text-align: center;
      height: 20px;
      font-size: 26px;
      font-weight: bold;
      line-height: 0.83;
    }

    &__subtitle {
      text-align: center;
      height: 20px;
      padding-top: 12px;
      font-size: 20px;
      font-weight: normal;
    }

    &__footer-title {
      margin-top: 42px;
      width: 367px;
      height: 20px;
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    &__products-wrapper {
      padding-top: 37px;
      display: flex;
      flex-direction: row;

      &__Quality-Keychains {
        width: 150px;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }

      &__Flexible-Magnets {
        width: 173px;
        margin-left: 45px;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }

      &__Chrome-Stickers {
        width: 181px;
        margin-left: 45px;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }

    .MZFront {
      margin-top: 9px;
    }

    .MZBack {
      margin-top: 18px;
    }

    .MKFront {
      margin-top: 7px;
    }

    .MKBack {
      margin-top: 19px;
    }
    .FlexMagnet {
      margin-top: 9px;
    }
  }

  &__how-it-works-banner {
    width: 768px;
    height: 588px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;

    &__left-panel {
      flex: 6;
      padding: 92px 25px 0px 41px;

      &__title {
        width: 328px;
        font-family: Ubuntu;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.56;
        letter-spacing: normal;
        color: #1c659f;
      }

      &__bullet-point p {
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #596066;
      }
    }

    &__right-panel {
      flex: 8;
    }
  }

  &__download-agreement-banner {
    padding-top: 23px;
    padding-bottom: 23px;
    background-color: #ffffff;

    a {
      text-decoration: none;
    }

    &__button-wrapper {
      width: 400px;
      height: 80px;
      border-radius: 41px;
      background-color: #d85b5e;
      margin-right: 150px;
      margin-left: 149px;
      padding: 24px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__text {
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .benifits-banner {
    width: 768px;
    height: 474px;
    background-color: #d85b5e;
    padding: 39px 43px 56px 40px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .benifits-banner > .title {
    height: 20px;
    font-family: Ubuntu;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: normal;
    color: #ffffff;
  }
  .benifits-banner > .benifits-banner-content {
    margin-top: 19px;
    display: flex;
    flex-direction: row;
  }
  .benifits-banner > .benifits-banner-content > .panel {
    max-width: 334px;
    display: flex;
    flex-direction: column;
  }

  .benifits-banner > .benifits-banner-content > .panel:not(:first-child) {
    padding-left: 15px;
  }

  .benifits-banner > .benifits-banner-content > .panel > p {
    margin-bottom: 20px;
  }
}

@include breakpoint(768px down) {
  .fundraiser {
    width: 100%;

    &__top-banner {
      height: auto;

      &__first-line {
        padding-left: 25%;
        padding-top: 10%;
      }

      &__second-line {
        padding-left: 25%;
        padding-bottom: 10%;
      }
    }

    &__customized-products-banner {
      height: unset;

      &__title {
        width: unset;
        padding-left: 25%;
      }

      &__subtitle {
        width: unset;
        padding-left: 25%;
      }

      &__footer-title {
        width: unset;
        padding-left: 25%;
      }

      &__products-wrapper {
        padding-left: 25%;
      }
    }

    &__how-it-works-banner {
      width: 100%;

      &__left-panel {
        flex: 1;
        padding: 92px 0px 0px 41px;
      }

      &__right-panel {
        display: none;
      }
    }

    .benifits-banner {
      display: flex;
      flex-direction: column;
      padding-left: 25%;

      .title {
        padding-top: 39px;
      }
    }
  }
}
